import { updateObject, cleanWWDOLocalStorage, setWWDOLocalStorage } from "../../lib";
import actionTypes from "../actions/action-types";

const initialJobData = {
  listingTerm: "0",
  billingCode: "baseprice"
};

const initialState = {
  step: 0,
  // TODO: move this hard-coded value to a variable
  jobData: updateObject(initialJobData, JSON.parse(localStorage.getItem("wwdo-job"))),
  success: false,
  error: null
};

const submitFormData = (state, action) => {
  setWWDOLocalStorage(action.jobData);
  return updateObject(state, {
    jobData: action.jobData
  });
};

const restart = (state) => {
  cleanWWDOLocalStorage({ force: true });
  return updateObject(state, initialState);
};

const postJobSuccessful = (state, action) => {
  return updateObject(state, {
    jobData: updateObject(state.jobData, action.result),
    success: true
  });
};

const postJobFailure = (state, action) => {
  return updateObject(state, {
    error: action.error
  });
};

const setJobBillingCode = (state, action) => {  
  return updateObject(state, {
    jobData: updateObject(state.jobData, { billingCode: action.billingCode })
  });
};

const setStep = (state, action) => {
  return updateObject(state, {
    step: action.step
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STEP:
      return setStep(state, action);
    case actionTypes.SUBMIT_FORM_DATA:
      return submitFormData(state, action);
    case actionTypes.SET_JOB_BILLING_CODE:
      return setJobBillingCode(state, action);
    case actionTypes.POST_JOB_SUCCESSFUL:
      return postJobSuccessful(state, action);
    case actionTypes.POST_JOB_FAILURE:
      return postJobFailure(state, action);
    case actionTypes.RESTART:
      return restart(state);
    default:
      return state;
  }
};

export {
  initialState,
  reducer
};
