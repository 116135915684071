import React from "react";
import "./HowToApply.css";

import { DOMPurify } from '../../lib';


const HowToApply = props => {

  function openEmail(event) {
    event.preventDefault();
    const mailSubject = `Job Application: ${props.job.jobTitle}`;
    window.location.assign(`mailto:${props.job.email}?subject=${encodeURI(mailSubject)}`);
  }

  let content = null;
  
  if (props.job.howToApply) {

    let purifiedContent = DOMPurify(props.job.howToApply);

    if (purifiedContent.charAt(0) === '<') {
      content = (
        <div
          className="section__group"
          style={{ overflowWrap: "break-word" }}
          dangerouslySetInnerHTML={{ __html: purifiedContent }}>
        </div>
      )
    } 
    
    // TODO: replace this with linkify-it module
    else {
      content = (
        <div
          className="section__group"
          style={{ overflowWrap: 'break-word' }}
        >
          <p style={{ marginBottom: "1em" }}>
            Please follow the instructions in the link below:
          </p>
          <p style={{ marginTop: '0' }}>
            <a
              href={purifiedContent}
              rel="noopener noreferrer"
              target="_blank"
            >{purifiedContent}</a>
          </p>
        </div>
      );
    }

  } 
  
  else {

    content = (
      <div
        className="section__group" 
        style={{ overflowWrap: 'break-word'}}
      >
        <p style={{ marginBottom: "1em" }}>
          Please send an email with your resumé to:
        </p>
        <p style={{ marginTop: '0' }}>
          <a
            href={`mailto:${props.job.email}`}
            onClick={openEmail}
          >{props.job.email}</a>
        </p>
      </div>
    );

  }

  return (
      <div className="form-apply howToApply">
        <div className="form__head">
          <h4>Ready to Apply?</h4>
        </div>
        <div className="form__body">
          {content}
        </div>
      </div>
  );
};

export default HowToApply;
