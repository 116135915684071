import { useRef, useEffect, useCallback } from 'react';

import { clearTimer } from '../lib';

function useDebounce(fn, wait = 200) {
    
  const timer = useRef(null);
  
  // clean up when unmounted
  useEffect(() => clearTimer(timer.current), []);

  // debounced function   
  return (
    useCallback(
      function ( ...args ) {
        clearTimer(timer.current);        
        timer.current = setTimeout(() => {
          fn.apply(this, args);
        }, wait);
      }, 
      [ fn, wait ]
    )
  );
}

export default useDebounce;