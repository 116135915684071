import React, { Fragment, useState, useContext, useCallback } from 'react';
import './CouponHandler.css';

import { Spinner, Input } from '../../../components/UI';

import { JobPostsContext } from '../../../context';

import { getBillingPlan, updateBilling } from '../../../store/actions';

const CouponHandler = ({ calculatePrice, saveBilling }) => {

  const context = useContext(JobPostsContext); 

  const { billingState, dispatchBilling } = context;

  const { 
    appliedCoupon, 
    processingCoupon
  } = billingState;

  const {
    nameOnCard,
    zipCode
  } = saveBilling;

  const [ billingCode, setBillingCode ] = useState('');

  const applyCoupon = useCallback(
    () => {
      dispatchBilling(updateBilling( { nameOnCard, zipCode } ));
      dispatchBilling(getBillingPlan(billingCode, calculatePrice));
    },
    [ 
      billingCode, 
      calculatePrice, 
      dispatchBilling,
      nameOnCard,
      zipCode
    ]
  );

  const couponChanged = useCallback(
    event => setBillingCode(event.target.value),
    []
  );

  const showCouponInput = {
    display: "block",
    marginBottom: "24px"
  };

  if (appliedCoupon || processingCoupon) {
    showCouponInput.display = "none";
  };
       
  return (
    <Fragment>
      {processingCoupon ? (
        <Spinner />
      ) : null } 
      <div style={showCouponInput}>
        <Input
          id="billing-code"
          type="text"
          label="If you have a discount code, enter it here:"
          labelClass="coupon-label"
          name="billingCode"
          placeholder="Discount Code"
          value={billingCode || ''}
          onChange={couponChanged}
        />
        <div className="form__actions">
          <button
            className="btn btn--green form__btn"
            onClick={applyCoupon}
          >
            Apply Discount
          </button>
        </div>
      </div>
    </Fragment>
  );

};

export default CouponHandler;