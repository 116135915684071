import React, { useState, useEffect } from "react";

import { fuzzySearch } from '../../lib';

import { useDebouncedValue } from '../../hooks';

import { Input } from "../../components/UI";

import "./Filter.css";

const Filter = ({ searchSpace, applyFilter, setFiltered }) => {

  const [filter, setFilter] = useState('');

  const debouncedFilter = useDebouncedValue(filter, 100);

  useEffect(
    () => {

      let searchResults;

      if (debouncedFilter) {
        searchResults = fuzzySearch(
          searchSpace,
          debouncedFilter
        );
      }

      else {
        searchResults = [];
      }

      if (setFiltered) {
        setFiltered(searchResults);
      }

      if (applyFilter) {

        if (debouncedFilter) {
          applyFilter(true);
        }

        else {
          applyFilter(false);
        }

      }

    },
    [debouncedFilter, applyFilter, searchSpace, setFiltered]
  );

  useEffect(
    () => {
      if (debouncedFilter === '' && applyFilter) {
        applyFilter(false);
      }
    },
    [ debouncedFilter, applyFilter ]
  );

  const handleFilter = event => {    
    event.preventDefault();
    setFilter(event.target.value);
  };

  return (
    <div className="filters-holder">
      <div className="filters__head">
        <form 
          onSubmit={event => { event.preventDefault(); }}
          className="filter-form"
        >
          <Input 
            id="job-filter"
            type="text"
            name="jobFilter"
            placeholder="Filter jobs by any keyword(s)"
            onChange={handleFilter}       
          />          
        </form>
      </div>
    </div>
  );
};

export default Filter;
