import { useReducer, useCallback } from 'react';

function useReducerWithThunk (reducer, initialState) {

  const [ state, dispatch ] = useReducer(reducer, initialState);

  const _dispatch = useCallback(
    action => {
      if (typeof action === 'function') {
        action(_dispatch);
      } else {
        dispatch(action); 
      }
    },
    [ dispatch ]
  );

  return [state, _dispatch];

}

export default useReducerWithThunk;