import React, { useMemo } from 'react';

import { useReducerWithThunk } from '../hooks';

import { blogReducer } from '../store/reducers';

const BlogContext = React.createContext({});

function BlogProvider ({ children }) {
  const [ state, dispatch ] = useReducerWithThunk(blogReducer.reducer, blogReducer.initialState);
  const value = useMemo(() => ({ state, dispatch }), [ state, dispatch ]);
  return (
    <BlogContext.Provider value={value}>{children}</BlogContext.Provider>
  );
}

const BlogConsumer = BlogContext.Consumer;

export {
  BlogContext,
  BlogProvider,
  BlogConsumer
};