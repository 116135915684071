import React, { useEffect, useContext, useCallback, Fragment } from "react";
import "./JobDetails.css";
import { withRouter } from "react-router"
import { BreadCrumbs, Spinner } from "../../components/UI";
import { NotFound, JobInfo } from "../../components";
import { JobsContext } from '../../context';
import * as actions from "../../store/actions";
import { isEmpty } from "../../lib";

const JobDetails = (props) => {

  const context = useContext(JobsContext);
  const { state, dispatch } = context;

  const getJobById = useCallback(id => dispatch(actions.getJobById(id)), [ dispatch ]);
  const filterJobId = useCallback(id => dispatch(actions.filterJobId(id)), [ dispatch ]);

  useEffect(
    () => {
      if (state.jobs.length) {
        filterJobId(props.match.params.id);
        if (!state.jobLoaded) {
          getJobById(props.match.params.id);
        }
      } else {
        getJobById(props.match.params.id);
      }
    },
    [ getJobById, filterJobId, props, state ]
  );

  let show = null;

  if (!state.jobLoaded) {
    show = <Spinner />;
  } 
  
  else if (state.error || isEmpty(state.job)) {
    show = <NotFound />;
  } 
  
  else {
    show = (
        <Fragment>
          <BreadCrumbs link="/jobs">Job Details</BreadCrumbs>
          <section id="job-details" className="section section--flex">
            <div className="shell">
              <div className="section__inner">
                <JobInfo
                  job={state.job}
                />
              </div>
            </div>
          </section>
        </Fragment>
      );
  }

  return <div className="main">{show}</div>;

};

export default withRouter(JobDetails);