import promiseDelay from './promise-delay';
import dbClient from './db-client';

// store names
const jobsIdbStore = process.env.REACT_APP_IDB_STORE_JOBS;
const blogIdbStore = process.env.REACT_APP_IDB_STORE_BLOG;

// IndexedDB configuration
const idbName = process.env.REACT_APP_IDB_DATABASE_NAME;
const idbStores = [jobsIdbStore, blogIdbStore];
const db = new dbClient(idbName, idbStores);

// // set response to the same signature of useAsyncEndpoint hook
// const setRes = (data, error = null) => {
//   return {
//     data,
//     pending: false,
//     complete: true,
//     error
//   };
// };

async function configIndexedDB (storeName, network ) {
  // caching strategy: request network to refresh, but fetch from IndexedDB first
  
  if ('indexedDB' in window) {
  
    // fetch from IndexedDB
    return db.readAll(storeName)
      .then(async data => {
        
        if (!data) {
          return Promise.reject(new Error('failed to fetch data from IndexedDB'));
        }

        if (data.length < 1) {
          // go to network anyway, with a large delay
          return await promiseDelay(network(), 10000);
        }
        
        else {
          // request to network will refresh IndexedDB in the background
          network();        
          // return setRes(data); 
          return data; 
        }

      });
          
  }

  else {
    return Promise.reject(new Error('IndexedDB is not available')); 
  }

}

export default configIndexedDB;