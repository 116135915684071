import createDOMPurify from "dompurify";

const DOMPurify = (dirtyHtml, customConfig) => {
  const purify = createDOMPurify(window);
  if (customConfig && customConfig.addTargetBlank) {
    purify.addHook("afterSanitizeAttributes", function(node) {
      // set all elements owning target to target=_blank
      if ("target" in node) {
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noopener  noreferrer");
      }
    });
  }
  const allowed = customConfig
    ? customConfig.allowed
    : {
        ALLOWED_TAGS: [
          "b",
          "i",
          "u",
          "ul",
          "ol",
          "li",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "blockquote",
          "p",
          "pre",
          "code",
          "a"
        ]
      };
  return purify.sanitize(dirtyHtml, allowed);
};

export default DOMPurify;