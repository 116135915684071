import Dexie from 'dexie';

class dbClient {

  constructor (dbName, storeNames, dbVersion = 1) {

    if (!dbName) {
      throw new Error('missing IndexedDB database name');
    }
    if (!storeNames || storeNames.length < 1) {
      throw new Error('missing IndexedDB store names');
    }

    const dbStores = {};
    storeNames.forEach(name => {
      // set an index on the id key in each store
      dbStores[name] = 'id';
    });

    this.dbName = dbName;
    this.storeNames = storeNames; 
    this.dbVersion = dbVersion;

    this.db = new Dexie(dbName);

    this.db
      .version(dbVersion)
      .stores(dbStores);

  }

 write (storeName, data) {
    return this.db[storeName].put(data);
  }
  
  writeBulk (storeName, data) {
    return this.db[storeName].bulkPut(data);
  }

  readAll (storeName) {
    return this.db[storeName].toArray();
  }

  clearAll (storeName) {
    return this.db[storeName].toCollection().delete();
  }

  deleteItem (storeName, id) {
    return this.db[storeName].delete(id);
  }

}

export default dbClient;