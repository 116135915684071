import React, { Fragment } from "react";
import "./JobOptions.css";

const JobOptions = props => {
  let list = null;
  let provider = null;
  let date = props.date || null;
  
  if (props.expiration) {
    const dateFormat = {
      day: "numeric",
      month: "short"
    };

    date = `Post expires on: ${new Date(
      new Date(0).setUTCSeconds(date)
    ).toLocaleDateString("en-us", dateFormat)}`;
  }

  if (props.options) {
    list = props.options.map((option, i) => <li key={i}>{option}</li>);
  }


  if (props.provider) {
    
    let offset = '';

    if (props.providerOffset) {
      offset = " job-provider--offset";
    }

    if (props.provider === 'github') {
      provider = <sup className={`job-provider${offset}`}>A <a
        href="https://jobs.github.com/"
        rel="noopener noreferrer"
        target="_blank">Github Jobs</a> Listing</sup>;
    }
   
    else if (props.provider === 'stackoverflow') {
      provider = <sup className={`job-provider${offset}`}>A <a
        href="https://stackoverflow.com/jobs"
        rel="noopener noreferrer"
        target="_blank">StackOverflow Jobs</a > Listing</sup>;
    }

    else {
      provider = <sup className={`job-provider${offset}`}>A <a
        href="https://weworkdevops.com">We Work DevOps</a> Listing</sup>;
    }

  }

  return (
    <Fragment>
    
      {provider}
      <ul className="list-options">
        {props.date ? <li key="date">{date}</li> : null}
        {list}
      </ul>

    </Fragment>
  );
};

export default JobOptions;
