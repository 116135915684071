import React from "react";
import "./Job.css";

import { Link, withRouter } from "react-router-dom";

import Excerpt from "./Excerpt";
import SuccessHead from "./SuccessHead";
import JobOptions from "../JobOptions";

import { mergeArrays } from "../../lib";

const clickableBreakpoint = 1024;

const handleClick = (history, link) => event => {
  event.preventDefault();
  const clickable = window.innerWidth < clickableBreakpoint;
  if (clickable) {
    history.push(link);
  }
};

const Job = props => {
  const head = props.applied ? <SuccessHead show={true} /> : null;
  const info = props.info;

  const jobLink = `/jobs/${info.id}`;

  const options = mergeArrays(info.jobOptions, info.jobType);

  return (
    <div onClick={handleClick(props.history, jobLink)} className="article job">
      {head}

      <div className="article__body">
        <h4>{info.jobTitle}</h4>
        <p style={{ margin: 0 }}>
          {info.companyName} - {info.jobLocation}
        </p>        
        <JobOptions 
          date={info.jobDate} 
          options={options}
          provider={info.provider} 
        />
      </div>

      <div className="article__footer">
        <Excerpt content={info.jobDescription} />

        <div className="article__actions">
          <Link to={jobLink} className="btn btn--blue btn--large">
            view this job
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Job);
