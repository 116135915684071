function validator (formData) {
  let valid = true;
  let issues = [];
  const requiredValues = [
    "jobLocation",
    "companyName",
    "jobDescription",
    "email",
    "jobOptions",
    "jobType",
    "jobTitle"
  ];


  // check required values
  requiredValues.forEach(value => {
    if (!formData[value] || !formData[value].length) {
      issues.push(value);
      valid = false;
      return { valid, issues };
    }
  });

  if (!valid) {
    return { valid, issues };
  }

  // if (
  //   !parsedJobDescription.includes("blocks") ||
  //   !parsedJobDescription.includes("entityMap")
  // ) {
  //   valid = false;
  // }

  // if (formData.jobOptions.length !== 1) {
  //   issues.push("jobOptions");
  //   valid = false;
  // } 
  
  // if (formData.jobType.length !== 1) {
  //   issues.push("jobType");
  //   valid = false;
  // }

  let emailRegex = /[^@]+@[^.]+\..+/g;
  if (!formData.email.match(emailRegex)) {
    issues.push("email");
    valid = false;
  }

  return valid ? { valid } : { valid, issues };

}

export default validator;
