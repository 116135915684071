import React, { useContext, useEffect, useState, useCallback } from 'react';
import './JobList.css';
import { IsotopeGrid, WrapWith } from '../../components/hoc';
import { Spinner } from '../../components/UI';
import { Job, Filter, PaginationNav } from '../../components';
import { JobsContext } from '../../context';
import { getJobs, filterJobs } from '../../store/actions';

import { usePagination } from '../../hooks';

const JobList = () => {

  const context = useContext(JobsContext);

  const { state, dispatch } = context;

  const [ hasFilter, setHasFilter ] = useState(false);

  const handleFilter = useCallback(setHasFilter, []);
  const setFilteredJobs = useCallback(filteredJobs => dispatch(filterJobs(filteredJobs)), [ dispatch ]);
  
  useEffect(
    () => {
      dispatch(getJobs());
    },
    [ dispatch ]
  );

  let list, jobs, jobsSection = <Spinner />;

  // when loaded
  if (state.loaded) {

    // when no jobs
    if (!state.jobs.length) {
      jobsSection = (
        <h2 className="message">
          More listings coming soon. Meanwhile, you can sign up for our <a href="https://mailing.weworkdevops.com" target="_blank" rel="noopener noreferrer">mailing list</a>.
        </h2>
      );
    }

    // when filter applied 
    else if (hasFilter) {
      // when nothing found
      if (!state.filteredJobs.length) {         
        jobsSection = (
          <h2 className="message">Sorry, no jobs were found that match those criteria.</h2>
        );                
      }
      else {
        jobs = state.filteredJobs;
      }
    }

    // no filter applied
    else {
      jobs = state.jobs;
    }
      
  }

  // paginate
  
  const startAtPage = 1;
  const pageSize = 9;

  const [ paginator, pageItems, setPage ] = usePagination(jobs, pageSize, startAtPage);

  if (pageItems.length) {
    list = pageItems.map(job => (
      <WrapWith key={job.id} id={job.id} classes="col col--1of3">
        <Job info={job} />
      </WrapWith>
    ));
  
    jobsSection = (
      <IsotopeGrid
        classes="cols cols--isotope"
        selector="col"
      >
        {list}
      </IsotopeGrid>
    );
  }



  return (
    <div className="main">
      <section className="section-jobs">
        <div className="shell">
          <header className="section__head">              
            <Filter 
              searchSpace={state.jobs} 
              setFiltered={setFilteredJobs}
              applyFilter={handleFilter}
            />
          </header>
          <div className="section__body">
            <div className="articles">{jobsSection}</div>
          </div>
          <PaginationNav 
            paginator={paginator}
            setPage={setPage}
          />
        </div>
      </section>
    </div>
  );

};

export default JobList;
