import React from 'react';
import { Router  } from 'react-router-dom';
import './App.css';

import Routes from './Routes';

import { Layout } from './components';

import { ScrollToTop } from './components/hoc';

import { cleanWWDOLocalStorage, history } from './lib';

import { useWindowUnload } from './hooks';

const App = () => {
  
  useWindowUnload(cleanWWDOLocalStorage);
  
  return (
    <div className="App">
      <Router history={history}>
        <ScrollToTop>
          <Layout>
            <Routes />
          </Layout>
        </ScrollToTop>
      </Router>
    </div>
  );
};

export { history };
export default App;
