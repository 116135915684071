import React, { Fragment } from 'react';
import './JobInfo.css';

import JobOptions from '../JobOptions';
import { HowToApply } from '../../components';

import { 
  DOMPurify,
  mergeArrays
} from "../../lib";

const JobInfo = ({ job }) => {

  const jobOptions = mergeArrays(
    job.jobOptions,
    job.jobType
  );
    
  // parse job description
  let jobDescription = null;

  if (job.jobDescription) {
    jobDescription = DOMPurify(job.jobDescription);
  }

    return (
      <Fragment>
        <header className="section__head">
          <h4>{job.jobTitle}</h4>
          <h5>
            {job.companyName} - {job.jobLocation}
          </h5>
          <JobOptions 
            date={job.jobDate} 
            options={jobOptions} 
            provider={job.provider}
            providerOffset={true}
          />
        </header>

        {jobDescription ?
          <div className="section__body">
            <div className="section__group">
                <h5>Job Details</h5>
                <div
                  dangerouslySetInnerHTML={{ __html: jobDescription }}
                  className="job-description"
                  style={{ overflowWrap: "break-word" }}
                />
            </div>
          </div>
        : null}

        <footer className="section__foot">
          <HowToApply job={job} />
        </footer>

      </Fragment>
    );
}

export default JobInfo;