import React from "react";

import { Link } from "react-router-dom";

import "./About.css";

const About = () => (
  <div className="main">
    <section className="section-main">
      <div className="shell">
        <div className="section__entry About">
          <h1>About We Work DevOps</h1>

          <h2>The Community-focused DevOps Jobs Site</h2>

          <article>
            <p>We list DevOps jobs, nothing else.</p>
            <p>
              We focus on working with companies that understand DevOps and the
              DevOps community.
            </p>
            <p>
              From conferences to bootcamps and jump-start organizations, the
              DevOps Community is robust, rapidly growing, and constantly
              changing. We get that, so we work with companies that want to feed
              it.
            </p>
            <p>
              We put a portion of the cost from every listing directly into our
              Community Fund where it is disbursed to organizations that help
              define and refine DevOps and the DevOps Community.
            </p>
            <p>
              Know an organization that should benefit from the Community Fund?
              Let us know!
            </p>
            <p>You can reach us at community@weworkdevops.com</p>
          </article>

          <div className="section-main__actions">
            <Link to="/" className="btn btn--blue">
              find your job
            </Link>
            <Link to="/post-a-job" className="btn btn--blue">
              post a job
            </Link>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default About;
