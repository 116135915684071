import React, { useContext, useCallback, useEffect, useState, Fragment } from "react";

import { Input } from "../../../components/UI";

import PaymentFormHead from "../PaymentFormHead";
import CardSection from "../CardSection";
import CouponHandler from '../CouponHandler';

import { JobPostsContext } from '../../../context';

import { 
  restart,
  restartBilling,
  sendJob,
  setCouponError,
  updateBilling,
  setJobBillingCode,
  setStep
} from "../../../store/actions";

import { injectStripe } from "react-stripe-elements";

const StripeInjectedForm = props => {

  const {
    stripe,
    setProcessing
  } = props;
  
  const context = useContext(JobPostsContext);

  // context
  const { state, dispatch, billingState, dispatchBilling }  = context;
  const { jobData, error, success } = state;

  const months = Number(jobData.listingTerm) + 1; // listingTerm is zero-based 
  
  const {
    nameOnCard,
    zipCode,
    price,
    discount, 
    pricePerMonth,
    couponMessage,
    showCouponMessage,
    billingPlan
  } = billingState;
  
  // The only currency accepted for now is USD
  const CURRENCY = billingPlan.currency || 'usd';

  // contextual dispatches  
  const postJob = useCallback((jobData, stripeChargeData) => dispatch(sendJob(jobData, stripeChargeData)), [ dispatch ]);
  const couponError = useCallback(error => dispatchBilling(setCouponError(error)), [ dispatchBilling ]);
  const setBilling = useCallback(billing => dispatchBilling(updateBilling(billing)), [ dispatchBilling ]);
  const restartFlow = useCallback(
    () => {
      dispatch(restart());
      dispatchBilling(restartBilling());
    }, 
    [ dispatch, dispatchBilling ]
  );
  
  const [ name, setName ] = useState(nameOnCard, '');
  const [ zip, setZip ] = useState(zipCode, '');

  // debounced updates to context state, used to save non-Stripe-handled values for reloads
  const nameChanged = useCallback(event => setName(event.target.value), []);
  const zipCodeChanged = useCallback(event => setZip(event.target.value), []);

  // TODO: error handling
  // set error in context
  const paymentFailure = useCallback(data => {
    setProcessing(false);
    alert("There was an error processing your payment. Please try again.");
  }, [ setProcessing ]);
  
  // handle error and success
  useEffect(
    () => {
      if (error) {
        paymentFailure(error);        
      }
      if (success) {
        dispatch(setStep(3));
      }
    },
    [ error, paymentFailure, dispatch, success ]
  );
  
  const calculatePrice = useCallback(
    (billingPlan) => {

      if (      
        billingPlan.couponType === 'plan' &&
        billingPlan.hasOwnProperty("pricePerMonth")) {

        const monthlyDiscount = 5000;
        const originalTotal = pricePerMonth - (monthlyDiscount * (months - 1));
        const planTotal = billingPlan.pricePerMonth * months;
        const newDiscount = originalTotal - planTotal;

        // already has a discount
        if (discount) {

          const couponMessage = "Coupon codes can't be stacked with discounts for longer listing terms. We've automatically given you the lowest price between the two discounts.";

          // give the lowest price
          if (originalTotal > planTotal) {
            dispatch(setJobBillingCode(billingPlan.couponCode));
            return setBilling({
              discount: newDiscount,
              price: planTotal,
              couponMessage
            });
          }
          else { 
            return setBilling({
              price: originalTotal,
              couponMessage
            });
          }
          
        } 
        
        // no discount yet
        else {
          dispatch(setJobBillingCode(billingPlan.couponCode));
          return setBilling({            
            discount: newDiscount,
            price: planTotal
          });
        }

      }
      
      // when billingPlan.pricePerMonth does not exist
      else {
        return couponError();
      }

    },
    [ 
      couponError,
      setBilling,
      dispatch,
      discount,
      months,
      pricePerMonth
    ]
  );

  // get a payment token from Stripe
  const createToken = useCallback((name, zip) => stripe.createToken({ name, address_zip: zip })
    .then(result => {
      if (result.error) {
        const error = Object.assign(new Error(), result.error);
        throw error;
      }
      return result.token;
    }), [ stripe ]);

  // After Stripe token is received, send job data to server and move to Success step
  const onToken = useCallback(
    async (chargeDescription, token) => {

      const stripeChargeData = {
        stripeTokenId: token.id,
        charge: {
          currency: CURRENCY,
          amount: price
        },
        chargeDescription
      };
      postJob(jobData, stripeChargeData);      

    },
    [ 
      CURRENCY,
      jobData,
      postJob,
      price
    ]
  );

  // coordination of API calls:
  // first: get a token from Stripe
  // second: send job data with payment token to server
  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();

      const chargeDescription = `We Work DevOps Job Listing`;

      setProcessing(true);

      // free-of-charge coupon: no Stripe token
      if (price <= 0) {
        return onToken(chargeDescription, { id: '' });
      }

      try {
        // get token from Stripe
        const token = await createToken(nameOnCard, zipCode);
        // send data to server
        return onToken(chargeDescription, token);
      }

      catch (error) {
        return paymentFailure(error);
      }

    },
    [ 
      price,
      createToken, 
      onToken,
      paymentFailure,
      setProcessing,
      nameOnCard,
      zipCode
    ]
  );

  const handleCancel = useCallback(
    event => {
      event.preventDefault();
      if (window.confirm("Are you sure you want to cancel?")) {
        restartFlow();
      }
    },
    [ restartFlow ]
  );

  const freeOfCharge = price <= 0;

  const show = {
    display: "block"
  };

  if (freeOfCharge) {
    show.display = "none";
  }
  
  return (            
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentFormHead
        amount={price / 100}
        discount={discount / 100}
        months={months}
      />

      {showCouponMessage ? (
        <p style={{ marginTop: "1em" }}>
          {couponMessage}
        </p>
      ) : null}

      <div className="form__body">

        {freeOfCharge ? (
          <Fragment>
            <h2>Your listing will be free of charge!</h2>
            <p>Please click Submit below to confirm.</p>
          </Fragment>
        ) : null}

        <div style={show}>
          <Input
            id="name-on-card"
            label="Name on Card:"
            name="nameOnCard"
            placeholder="Name"
            value={name || ''}
            onChange={nameChanged}
          />

          <CardSection />

          <Input
            id="zip-code"
            label="Zip Code:"
            name="zipCode"
            placeholder="Zip Code"
            value={zip || ''}
            onChange={zipCodeChanged}
          />

        </div>

        <CouponHandler 
          calculatePrice={calculatePrice}
          saveBilling={{ nameOnCard: name, zipCode: zip  }}
        />

        <hr />

        <div className="form__actions">
          <button
            onClick={handleCancel}
            className="form__btn btn btn--grey"
            type="cancel"
          >
            CANCEL
          </button>
          <button type="submit" className="form__btn btn btn--blue">
            SUBMIT
          </button>
        </div>
      </div>
    </form>
        
  );
  
}

export default injectStripe(StripeInjectedForm);