import React, { useState, useEffect, useCallback } from 'react';
import ReactMde from 'react-mde';
import ReactMarkdown from 'react-markdown';
import { Spinner } from '../UI';

import 'react-mde/lib/styles/css/react-mde-all.css';
import './MarkdownEditor.css'

// Documentation:
// https://github.com/andrerpena/react-mde

const MarkdownEditor = (props) => {

  const { 
    label, 
    labelClass,
    name,
    onChange,
    value
  } = props;

  const [ _value, _setValue ] = useState(value);
  const [ selectedTab, setSelectedTab ] = useState('write');

  const callbackToChange = useCallback(onChange, []);

  useEffect(
    () => {
      // emulating event to pass to useForm hook
      const emulatedEvent = {
        target: {
          name,
          value: _value
        }
      };
      
      callbackToChange(emulatedEvent);
    },
    [ callbackToChange, _value, name ]
    );

  return (
    <div className="form__row form__row--large">
      <div className="form__controls">
        {label ? (
          <label className={`form__label ${labelClass || ''}`}>
            {label}
          </label>
        ) : null }

        <div className="markdown-editor">     
          <ReactMde
            value={value}
            onChange={_setValue}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={markdown =>
              Promise.resolve(<ReactMarkdown source={markdown} />)
            }
            loadingPreview={<Spinner/>}
          />
        </div>
      </div>
    </div>
  );


};

export default MarkdownEditor;