function mergeArrays() {
  const result = [];

  for (let i = 0; i < arguments.length; i++) {
    if (Array.isArray(arguments[i])) {
      result.push.apply(result, arguments[i]);
    }
  }

  return result;
}

export default mergeArrays;
