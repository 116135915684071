import React, { Fragment } from "react";
import "./CardSection.css";

// https://stripe.com/docs/recipes/elements-react
// https://github.com/stripe/react-stripe-elements#element-components
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "react-stripe-elements";

const styles = {
  base: {
    fontWeight: "400",
    fontSize: "14px",
    "::placeholder": {
      fontWeight: "600",
      fontFamily: '"Nunito Sans", sans serif',
      color: "#c1c1c1"
    }
  }
};

const CardSection = () => (

  <Fragment>
    <div className="form__row">
      <div className="form__controls">
        <label htmlFor="card-number" className="form__label">
          Card Number:
        </label>
        <CardNumberElement
          id="card-number"
          placeholder="Card Number"
          className="field"
          style={styles}
        />
      </div>
    </div>

    <div className="form__group">
      <div className="form__row">
        <div className="form__controls">
          <label htmlFor="expiration-date" className="form__label">
            Expiration Date:
          </label>
          <CardExpiryElement
            id="expiration-date"
            className="field"
            placeholder="MM/YY"
            style={styles}
          />
        </div>
      </div>

      <div className="form__row">
        <div className="form__controls">
          <label htmlFor="security-code" className="form__label">
            Security Code:
          </label>
          <CardCvcElement
            id="security-code"
            className="field"
            placeholder="CVC"
            style={styles}
          />
        </div>
      </div>
    </div>

  </Fragment>
);

export default CardSection;
