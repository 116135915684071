import { useEffect, useRef } from 'react';

function addHandler (handler) { 
  window.addEventListener('beforeunload', handler);
}

function removeHandler (handler) {
  window.removeEventListener('beforeunload', handler);
}

function useWindowUnload (handler) {

  const prevHandler = useRef(null);
  
  useEffect(() => {
    
    if(prevHandler.current) {
      // remove the current event listener, if one exists
      removeHandler(prevHandler.current); 
    } 
    
    prevHandler.current = handler;
    
    addHandler(handler);
    
    return () => {      
      handler(); 
      removeHandler(handler);
    }
  }, [handler])
}

export default useWindowUnload;