import React, { useContext } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import JobPostForm from '../../containers/JobPostForm';
import PaymentForm from '../../containers/PaymentForm';
import JobSummary from '../JobSummary';
import Success from '../Success';

import { JobPostsContext } from '../../context';

export const basePath = '/post-a-job';

export const routesConfig = [ 
  {
    step: 0,
    path: 'details',
    component: JobPostForm
  },
  {
    step: 1,
    path: 'summary',
    component: JobSummary
  }, 
  {
    step: 2,
    path: 'payment',
    component: PaymentForm
  },
  {
    step: 3,
    path: 'success',
    component: Success
  } 
];

const JobPostRoutes = ({ match }) => {

  function find (name) {
    return routesConfig.find(r => r.path === name);
  }

  const context = useContext(JobPostsContext);

  const step = context.state.step;

  const stepConfig = find(match.params.step);

  const StepRoute = ({ config }) => {

    if (!config || config.step > step) {
      return <Redirect to={basePath} />;
    }

    let Step = config.component;

    // FIX THIS: I don't know why component is giving undefined in config for this one only 
    if (step === 1) {
      Step = JobSummary;
    }

    return <Step />; 

  };

  return (
    <StepRoute config={stepConfig} />
  );

};

export default withRouter(JobPostRoutes);