import React, { Fragment, useState } from "react";

import Navigation from "../Navigation";

const MobileNav = () => {
  const [show, setShow] = useState(false);

  const isActive = show ? " nav-trigger--active" : "";

  const slideMenu = {
    display: "block",
    paddingTop: "1em"
  };

  const showHandler = () => {
    setShow(!show);
  };

  return (
    <Fragment>
      <div
        style={{ cursor: "pointer" }}
        className={"nav-trigger" + isActive}
        onClick={showHandler}
      >
        <span />
        <span />
        <span />
      </div>

      {show ? <Navigation style={slideMenu} clicked={showHandler} /> : null}
    </Fragment>
  );
};

export default MobileNav;
