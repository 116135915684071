import React, { Fragment } from "react";

import "./PaymentFormHead.css";

const PaymentFormHead = props => {
  let totalMonths = props.months > 1 ? props.months : null;

  return (
    <div className="form__head">
      <h5>Please Add Your Payment Information</h5>

      <table className="pricing-table">
        <tbody>
          {props.discount ? (
            <Fragment>
              <tr>
                <th>Price</th>
                <td>
                  $299.00 per month {totalMonths ? `(x${totalMonths})` : null}
                </td>
              </tr>
              <tr>
                <th>Discounts</th>
                <td>
                  ${props.discount.toFixed(2)} per month{" "}
                  {totalMonths ? `(x${totalMonths})` : null}
                </td>
              </tr>
            </Fragment>
          ) : null}
          <tr>
            <th>Total</th>
            <td>${props.amount.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>

    </div>
  );
};

export default PaymentFormHead;
