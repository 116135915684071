import { getBlogs } from "../../api-requests";
import actionTypes from "./action-types";

const fetchBlogsSuccess = blogPosts => {
  return {
    type: actionTypes.FETCH_BLOG_POSTS_SUCCESS,
    blogPosts
  };
};

const fetchBlogsFailure = error => {
  return {
    type: actionTypes.FETCH_BLOG_POSTS_FAILURE,
    error
  };
};

const getBlogPosts = () => async dispatch => {
  try {
    const blogs = await getBlogs();
    dispatch(fetchBlogsSuccess(blogs));
  } catch (error) {
    dispatch(fetchBlogsFailure(error));
  }
};

export { 
  getBlogPosts
};