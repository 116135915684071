import { updateObject } from  '../../lib';
import actionTypes from '../actions/action-types';

const initialBillingPlan = {
  couponCode: "baseprice",
  couponType: "plan",
  currency: "usd",
  pricePerMonth: 29900
};

const initialState = {
  nameOnCard: '',
  zipCode: '',
  pricePerMonth: 29900,
  price: 29900,
  showCouponMessage: false,
  processingCoupon: false,
  discount: 0,
  couponMessage: "Coupon code added successfully!",
  couponError: null,
  appliedCoupon: false,
  billingPlan: initialBillingPlan
};

const setProcessingCoupon = (state, action) => {
  return updateObject(state, {
    processingCoupon: action.processingCoupon
  });
};

const setCouponError = (state, action) => {
  return updateObject(state, {
    processingCoupon: false,
    showCouponMessage: true,
    appliedCoupon: false,
    couponMessage: action.message || "Something went wrong adding that coupon, please try again.",        
    couponError: action.error || new Error('invalid billing plan')
  });
};

const setBillingPlan = (state, action) => {
  return updateObject(state, {
    processingCoupon: false,
    showCouponMessage: true,
    appliedCoupon: true,
    billingPlan: updateObject(state.billingPlan, action.billingPlan)
  });
};

const updateBilling = (state, action) => {
  return updateObject(state, action.billing);
};

const restartBilling = (state) => {
  return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROCESSING_COUPON:
      return setProcessingCoupon(state, action);
    case actionTypes.SET_COUPON_ERROR:
      return setCouponError(state, action);
    case actionTypes.SET_BILLING_PLAN:
      return setBillingPlan(state, action);
    case actionTypes.UPDATE_BILLING:
      return updateBilling(state, action);
    case actionTypes.RESTART_BILLING:
      return restartBilling(state);
    default:
      return state;
  }
};

export {
  initialState,
  reducer
};