import React, { Fragment, useContext } from "react";

import { JobPostsContext } from '../../context';

import { BreadCrumbs } from "../UI";
import { JobInfo } from '../../components';

import { setStep } from '../../store/actions';

const JobSummary = () => {
  
  const context = useContext(JobPostsContext);
  const { state, dispatch } = context;
  
  const job = state.jobData;

  const setCheckoutStep = step => dispatch(setStep(step));

  const editJob = event => {
    event.preventDefault();
    setCheckoutStep(0);
  };

  const checkout = event => {    
    event.preventDefault();
    setCheckoutStep(2);
  };

  return (
    <Fragment>
      <BreadCrumbs>Review Job</BreadCrumbs>

      <section className="section section--flex">
        <div className="shell">
          <div className="section__inner">
            <JobInfo job={job} />
            <div className="section__actions">
              <a href="/post-a-job" onClick={editJob} className="section__link-edit">
                edit job
              </a>

              <button onClick={checkout} className="btn btn--blue">
                add payment
              </button>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default JobSummary;
