import React, { Component } from "react";
import ReactDOM from "react-dom";
import Isotope from "isotope-layout";

import { shallowEqual, debounce } from '../../../lib';

// See:
// https://stackoverflow.com/questions/25135261/react-js-and-isotope-js
// https://developers.redhat.com/blog/2016/01/07/react-js-with-isotope-and-flux/

class IsotopeGrid extends Component {
  state = {
    isotope: null
  };

  loadIsotope = () => {

    if (!this.state.isotope) {
      
      const grid = ReactDOM.findDOMNode(this);
      
      const isoOptions = {
        itemSelector: `.${this.props.selector}`,
        layoutMode: 'masonry',
        masonry: {
          columnWidth: `.${this.props.selector}`
        }
      };
      
      this.setState({
        isotope: new Isotope(grid, isoOptions)
      });
    } 
    
    else {
      this.state.isotope.reloadItems();
      this.state.isotope.arrange();
    }

  };

  handleResize = debounce(() => {
    if (window.innerWidth > 767) {
      this.loadIsotope();
    }
  }, 10);

  // set up isotope
  componentDidMount() {
    this.loadIsotope();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    this.setState({ isotope: null });
  }

  // update isotope layout
  componentDidUpdate() {
   this.loadIsotope();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState);
  }

 
  render() {
    return <div className={this.props.classes}>{this.props.children}</div>;
  }
}

export default IsotopeGrid;
