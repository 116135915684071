import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class ErrorHandler extends PureComponent {
  
  state = {
    error: null
  };

  static getDerivedStateFromError (error) {
    return { error };
  }

  componentDidCatch (error, info) {
    this.props.onError (error, info);
  }

  render () {
    if (this.state.error) {
      return this.props.errorElement
    }
    return this.props.children
  }
}

ErrorHandler.defaultProps = {
  errorElement: (<h2 className="message">Something went wrong!</h2>),
  onError: () => {}
};

ErrorHandler.propTypes = {
  errorElement: PropTypes.element,
  onError: PropTypes.func
};

export default ErrorHandler;