import Fuse from 'fuse.js';

function fuzzySearch (searchSpace = [], query = '', keys = []) {
   
  // TODO: arrayify searchSpace

  const searchOptions = {
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3
  };

  if (keys.length > 0) {
    searchOptions.keys = keys;
  }

  else {
    // search in values of all keys in the search space
  
    const allKeys = Object.keys(
      searchSpace[0] || {}
    );

    searchOptions.keys = allKeys;

  }

   const fuse = new Fuse(
      searchSpace, 
      searchOptions
    );

  const results = fuse.search(query);

  return results;
}

export default fuzzySearch;