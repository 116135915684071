import axios from "axios";
import { axiosError, promiseAny, promiseDelay, dbClient  } from '../lib';

const apiUrl = process.env.REACT_APP_API;
const apiKey = process.env.REACT_APP_API_KEY;

const blogIdbStore = process.env.REACT_APP_IDB_STORE_BLOG;
const jobsIdbStore = process.env.REACT_APP_IDB_STORE_JOBS;

// configure IndexedDB
const idbName = process.env.REACT_APP_IDB_DATABASE_NAME;
const idbStores = [jobsIdbStore, blogIdbStore];
const db = new dbClient(idbName, idbStores);

// caching strategy: request network to refresh, but fetch from IndexedDB first
const configIndexedDB = (storeName, network ) => async () => {

  if ('indexedDB' in window) {
  
    // fetch from IndexedDB
    return db.readAll(storeName)
      .then(async data => {
        
        if (!data) {
          return Promise.reject(new Error('failed to fetch data from IndexedDB'));
        }

        if (data.length < 1) {
          // go to network anyway, with a large delay
          return await promiseDelay(network(), 10000);
        }
        
        else {
          // request to network will refresh IndexedDB in the background
          network();        
          return data; 
        }

      });
          
  }

  else {
    return Promise.reject(new Error('IndexedDB is not available')); 
  }

};

export const fetchJobs = () => {
  
  const errorMessage = 'failed to fetch jobs';

  const fromNetwork = () => {

    const request = {
      url: `${apiUrl}/jobs`,
      method: "get",
      crossDomain: true,
      headers: {
        "X-Api-Key": apiKey
      }
    };
  
   return axios(request)
      .then(response => response.data)
      .catch(axiosError(errorMessage));

  };

  const fromIndexedDB = configIndexedDB('jobs', fromNetwork);

  // fetch whatever is faster
  return promiseAny([
    fromNetwork(),
    fromIndexedDB()
  ], errorMessage);
  

};

export const fetchJobById = id => {

  const request = {
    url: `${apiUrl}/jobs/${id}`,
    method: "get",
    crossDomain: true,
    headers: {
      "X-Api-Key": apiKey
    }
  };

  return axios(request)
    .then(response => response.data)
    .catch(axiosError(`failed to fetch job (id: ${id})`));

};

export const postJob = data => {

  const request = {
    method: "post",
    url: `${apiUrl}/jobs`,
    crossDomain: true,
    headers: {
      "X-Api-Key": apiKey,
      "Content-Type": "application/json"
    },
    data
  };

  return axios(request)
    .then(response => response.data.info)
    .catch(axiosError('failed to post job'));
};

// TODO: change back-end to billingCode instead of couponCode
export const fetchBillingPlan = billingCode => {
  const request = {
    method: "get",
    url: `${apiUrl}/billing/${billingCode}`,
    crossDomain: true,
    headers: {
      "X-Api-Key": apiKey
    }
  };

  return axios(request)
    .then(response => response.data.billing)
    .catch(axiosError(`failed to fetch billing plan: ${billingCode}`));

};


export const getBlogs = () => {

  const errorMessage = 'failed to fetch blog posts';

  const fromNetwork = () => {

    const request = {
      url: `${apiUrl}/blog`,
      method: "get",
      crossDomain: true,
      headers: {
        "X-Api-Key": apiKey
      }
    };
  
    return axios(request)
      .then(response => response.data)
      .catch(axiosError(errorMessage));

  };

  const fromIndexedDB = configIndexedDB('posts', fromNetwork);

  // fetch whatever is faster
  return promiseAny([
    fromNetwork(),
    fromIndexedDB()
  ], errorMessage);

};
