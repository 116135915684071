import { isCancel } from 'axios';

const axiosError = (errorMessage = 'axios error') => {
  
  return (
    
    /**
     * Handle errors from axios http requests 
     * by processing error responses and re-throwing
     * with a custom error message
     * 
     * @function axiosErrorHandler
     * @param error An error object
     * @throws
     * @see https://github.com/axios/axios#handling-errors
     */
    function axiosErrorHandler (error) {

      if(isCancel(error)) {
        throw new Error(error.message);
      }

      const newError = new Error(errorMessage);      

      if (error.response) {
        throw Object.assign(newError, error.response.data);
      } 
      
      else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        throw newError;
      } 
      
      else {
        // Something happened in setting up the request that triggered an Error
        throw new Error('network error');
      }
    }
  );

};

export default axiosError;