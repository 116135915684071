import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { scrollToTop } from '../../../lib';

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(
    () => {

      scrollToTop();

    }, 
    [ pathname ]
  );

  return children || null;
};

export default withRouter(ScrollToTop);
