import React, { useMemo } from 'react';

import { useReducerWithThunk } from '../hooks';

import { jobsReducer } from '../store/reducers';

const JobsContext = React.createContext({});

function JobsProvider ({ children }) {

  const [ state, dispatch ] = useReducerWithThunk(jobsReducer.reducer, jobsReducer.initialState);
  const value = useMemo(() => ({ state, dispatch }), [ state, dispatch ]);

  return (
    <JobsContext.Provider value={value}>{children}</JobsContext.Provider>
  );
}

const JobsConsumer = JobsContext.Consumer;

export {
  JobsContext,
  JobsProvider,
  JobsConsumer
};