import { updateObject } from "../../lib";
import actionTypes from "../actions/action-types";

// TODO: error handling
const initialState = {
  blogPosts: [],
  blogPostsLoaded: false,
  error: null
};

const fetchBlogsSuccess = (state, action) => {
  return updateObject(state, {
    blogPosts: action.blogPosts,
    blogPostsLoaded: true
  });
};

const fetchBlogsFailure = (state, action) => {
  return updateObject(state, {
    error: action.errorblogPosts,
    blogPostsLoaded: true
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BLOG_POSTS_SUCCESS:
      return fetchBlogsSuccess(state, action);
    case actionTypes.FETCH_BLOG_POSTS_FAILURE:
      return fetchBlogsFailure(state, action);
    default: 
      return state;
  }
};

export {
  initialState,
  reducer
};