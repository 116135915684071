/*
  Combining react-fast-compare and fast-deep-equal
  https://github.com/FormidableLabs/react-fast-compare
  https://github.com/epoberezkin/fast-deep-equal
*/

function getKeys(a) {
  var keys = [];
  for (let [key] of a.entries()) {
    keys.push(key);
  }
  return keys;
}

function equal(a, b) {
  if (a === b) return true;

  if (a && b && typeof a == 'object' && typeof b == 'object') {
    if (a.constructor !== b.constructor) return false;

    var length, i, key, keys;
    var hasElementType = typeof Element !== 'undefined';

    if (Array.isArray(a)) {
      length = a.length;
      if (length !== b.length) return false;
      for (i = length; i-- !== 0;)
        if (!equal(a[i], b[i])) return false;
      return true;
    }

    if (a instanceof Map) {
      if (a.size !== b.size) return false;

      keys = getKeys(a);
      for (i = a.size; i-- !== 0;)
        if (!b.has(keys[i])) return false;

      for (i = a.size; i-- !== 0;) {
        key = keys[i];
        if (!equal(a.get(key), b.get(key))) return false;
      }

      return true;
    }

    if (a instanceof Set) {
      if (a.size !== b.size) return false;

      keys = getKeys(a);
      for (i = a.size; i-- !== 0;)
        if (!b.has(keys[i])) return false;

      return true;
    }

    if (a.constructor.BYTES_PER_ELEMENT && (
      a instanceof Int8Array ||
      a instanceof Uint8Array ||
      a instanceof Uint8ClampedArray ||
      a instanceof Int16Array ||
      a instanceof Uint16Array ||
      a instanceof Int32Array ||
      a instanceof Uint32Array ||
      a instanceof Float32Array ||
      a instanceof Float64Array /* ||
      a instanceof BigInt64Array ||
      a instanceof BigUint64Array */
    )) {
      length = a.length;
      if (length !== b.length) return false;
      for (i = length; i-- !== 0;)
        if (a[i] !== b[i]) return false;
      return true;
    }

    if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags;
    if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf();
    if (a.toString !== Object.prototype.toString) return a.toString() === b.toString();

    keys = Object.keys(a);
    length = keys.length;
    if (length !== Object.keys(b).length) return false;

    for (i = length; i-- !== 0;)
      if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

    // start react-fast-compare
    // custom handling for DOM elements
    if (hasElementType && a instanceof Element)
      return false;

    for (i = length; i-- !== 0;) {
      key = keys[i];
      if (key === '_owner' && a.$$typeof) {
        // React-specific: avoid traversing React elements' _owner.
        //  _owner contains circular references
        // and is not needed when comparing the actual elements (and not their owners)
        // .$$typeof and ._store on just reasonable markers of a react element
        continue;
      } else {
        // all other properties should be traversed as usual
        if (!equal(a[key], b[key])) return false;
      }
      
    }
    // end react-fast-compare

    return true;
  }

  // true if both NaN, false otherwise
  return a !== a && b!== b; // eslint-disable-line
}

function deepEqual (a, b) {
  try {
    return equal(a, b);
  } catch (error) {
    if ((error.message && error.message.match(/stack|recursion/i)) || (error.number === -2146828260)) {
      // warn on circular references, don't crash
      // browsers give this different errors name and messages:
      // chrome/safari: "RangeError", "Maximum call stack size exceeded"
      // firefox: "InternalError", too much recursion"
      // edge: "Error", "Out of stack space"
      console.warn('Warning: deep equal comparison is not handling circular references.', error.name, error.message);
      return false;
    }
    // some other error. we should definitely know about these
    throw error;
  }
}

export default deepEqual;