import React from "react";

const Input = props => {
  const {
    id,
    classes,
    type,
    value,
    label,
    name,
    placeholder,
    onChange,
    modifier,
    defaultValue,
    required,
    labelClass
  } = props;

  return (
    <div className={`form__row${modifier ? " " + modifier : ""}`}>
      <div className="form__controls">
        {label ? (
          <label htmlFor={id} className={`form__label ${labelClass}`}>
            {label}
          </label>
        ) : null}

        {type === "textarea" ? (
          <textarea
            className={`field field--textarea${classes ? " " + classes : ""}`}
            onChange={onChange}
            type={type}
            defaultValue={defaultValue}
            value={value}
            name={name}
            id={id}
            placeholder={placeholder}
            required={required}
            wrap="hard"
          />
        ) : (
          <input
            className={`field${classes ? " " + classes : ""}`}
            onChange={onChange}
            type={type}
            defaultValue={defaultValue}
            value={value}
            name={name}
            id={id}
            placeholder={placeholder}
            required={required}
          />
        )}
      </div>
    </div>
  );
};

export default Input;
