// https://css-tricks.com/snippets/javascript/strip-html-tags-in-javascript/

function stripHtml(htmlString) {
  return htmlString
    .replace(/(<([^>]+)>)/gi, "") // remove html tags
    .replace(/[\r\n]+/gi, " ") // convert new lines into spaces
    .replace(/\s\s+/g, " "); // convert multiple spaces into single spaces
}

export default stripHtml;
