import React, { useState } from "react";

import { useStripe } from '../../hooks';

import { Elements, StripeProvider } from "react-stripe-elements";

import StripeInjectedForm from "./StripeInjectedForm";

import { Spinner, BreadCrumbs } from "../../components/UI";

const stripeSrc = process.env.REACT_APP_STRIPE_SRC;
const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const PaymentForm = () => {
  
  const stripe = useStripe(stripeSrc, stripeKey);

  const [ processing, setProcessing ] = useState(false);

  const fonts = [
    {
      cssSrc:
        "https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800"
    }
  ];

  const hideOrShow = {
    display: "block"
  };

  if (processing) {
    hideOrShow.display = "none";
  }

  return (
    <div className="main">

      {processing ? <Spinner /> : null}

      <div style={hideOrShow}>
        <BreadCrumbs offset={true}>Add Payment</BreadCrumbs>      
        <section className="section-form section-form--alt">
          <div className="shell">
            <div className="section__body">
              <div className="form form--alt">
                <StripeProvider stripe={stripe}>
                  <Elements fonts={fonts}>
                    <StripeInjectedForm
                      setProcessing={setProcessing}
                    />
                  </Elements>
                </StripeProvider>
              </div>
            </div>
          </div>        
        </section>
      </div>

    </div>
  );
  
};

export default PaymentForm;