import React from "react";
import Input from "../UI/Input";
import "./DynamicForm.css";

const DynamicForm = props => {
  let formValues = props.formValues.map(val => {
    let emailNotice =
      val.type === "email" ? (
        <sup>We will never sell or share your information. Period.</sup>
      ) : null;

    return (
      <div className="form__row" key={val.name}>
        <Input
          id={val.id || null}
          label={val.label || null}
          labelClass={val.labelClass}
          value={val.value}
          type={val.type}
          name={val.name}
          placeholder={val.placeholder}
          required={val.required || false}
          className="field"
        />
        {emailNotice}
      </div>
    );
  });

  return (
    <div className="section__body formContainer">
      <div className="form">
        <form>
          <div className="form__body">{formValues}</div>
          <div className="form__actions">
            <button
              type="submit"
              onClick={props.submit}
              className="btn btn--green"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default React.forwardRef((props, ref) => (
  <DynamicForm innerRef={ref} {...props} />
));
