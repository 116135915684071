import React from "react";

// import { Link } from 'react-router-dom';

import { WrapWith } from "../../hoc";

import "./BreadCrumbs.css";

const BreadCrumbs = props => {
  const classesList = ["breadcrumbs"];

  if (props.offset) {
    classesList.push("breadcrumbs--alt");
  }

  const breadcrumbClasses = classesList.join(" ");

  return (
    <WrapWith onClick={props.onClick} classes={breadcrumbClasses}>
      <WrapWith onClick={props.onClick} classes="shell">
        {/*<Link onClick={props.onClick} to={props.link ? props.link : "/"} className="link-back">
          {props.children}
        </Link>*/}
        <div className="link-back">{props.children}</div>
      </WrapWith>
    </WrapWith>
  );
};

export default BreadCrumbs;
