import actionTypes from "./action-types";
import { postJob } from '../../api-requests';

import { basePath, routesConfig } from '../../components/JobPostRoutes';

import { history } from '../../App';

const submitFormData = formData => {
  return {
    type: actionTypes.SUBMIT_FORM_DATA,
    jobData: formData
  };
};

const restart = () => {
  return {
    type: actionTypes.RESTART
  };
};

const setJobBillingCode = billingCode => {
  return {
    type: actionTypes.SET_JOB_BILLING_CODE,
    billingCode
  };  
};

const postJobSuccessful = result => {
  return {
    type: actionTypes.POST_JOB_SUCCESSFUL,
    result
  };
};

const postJobFailure = error => {
  return {
    type: actionTypes.POST_JOB_FAILURE,
    error
  };
};

const sendJob = (job, stripeCharge) => async dispatch => {
  try {
    const data = {
      job,
      stripeCharge
    };
    const result = await postJob(data);
    dispatch(postJobSuccessful(result));        
  }
  catch (error) {
    dispatch(postJobFailure(error));
  }
};

const setStep = (step = 0) => {
  history.push(`${basePath}/${routesConfig[step].path}`);
  return {
    type: actionTypes.SET_STEP,
    step
  };
};

export {
  setStep,
  submitFormData,
  setJobBillingCode,
  sendJob,
  restart
};
