import React, { useContext, useEffect } from 'react';
import './Blog.css';
import { BlogPost } from '../../components';
import { Spinner } from '../../components/UI';
import { BlogContext } from '../../context';
import { getBlogPosts } from "../../store/actions";

const Blog = () => {

    const context = useContext(BlogContext);

    const { state, dispatch } = context;

    useEffect(
        () => {
            dispatch(getBlogPosts());
        },
        [ dispatch ] 
    );
    
    let blogPosts;

    // TODO: error handling
    if (state.blogPostsLoaded) {
        blogPosts = state.blogPosts.map(post => {
            return (
                <BlogPost post={post} key={post.id} />
            );
        });
    } else {
        blogPosts = <Spinner />;
    }

    return (
        <div className="main">
            <section className="section-main">
                <div className="shell Blog">
                    {blogPosts}
                </div>
            </section>
        </div>
    );
};

export default Blog;