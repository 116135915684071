/**
 * Returns a  function that will not be triggered as long 
 * as it continues to be invoked. The function will then be called 
 * after it stops being called for N milliseconds (default 200 ms).
 *
 * @function debounce
 * @param {function} fn
 * @param {number} [wait=200]
 * @returns {function} debounced
 */
function debounce (fn, wait = 200) {
  let timeout = null;
  return function debounced () {    
    const functionCall = () => fn.apply(this, arguments);
    clearTimeout(timeout);    
    timeout = setTimeout(functionCall, wait);
  };
}

export default debounce;

