/**
* Delay a promise for n milliseconds
*
* @param {Promise} promise Any promise
* @param {number} delay Time in ms
* @returns Resolved or rejected value of promise
*/
function promiseDelay (promise, delay) {
  // make sure promise is a promise
  const promised = Promise.resolve(promise);
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      return promised
        .then(resolve)
        .catch(reject) 
    }, delay);
  });
}

export default promiseDelay;