import React, { useContext, useEffect, useCallback } from "react";

import { JobPostsContext } from '../../context';
import { restart, restartBilling } from '../../store/actions';

import { withRouter } from "react-router-dom";

const Success = ({ history }) => {

  const context = useContext(JobPostsContext);
  const dispatch = context.dispatch;
  const dispatchBilling = context.dispatchBilling;
  const jobData = context.state.jobData;

  const url = `/jobs/${jobData.id}`;

  const restartFlow = useCallback(
    () => {
      dispatch(restart());
      dispatchBilling(restartBilling());
    },
    [ dispatch, dispatchBilling ]
  );

  const goTo = path => event => {
    event.preventDefault();
    restartFlow();
    history.push(path);
  };

  useEffect(
    () => { 
      return () => { restartFlow(); }; 
    }, 
    [ restartFlow ]
  );

  return (
    <div className="main">
      <section className="section-main">
        <div className="shell">
          <div className="section__entry">
            <div className="cards">
              <h3 style={{ textAlign: "center" }}>Post a Job: Confirmation</h3>

              <div className="card">
                <div className="card__head">
                  <i className="ico-check" />
                  <h5>Success!</h5>
                  <p>Your job has been posted.</p>
                </div>

                <div className="card__body">
                  <h4>{jobData.jobTitle}</h4>
                  <p>
                    {jobData.companyName} - {jobData.jobLocation}
                  </p>

                  <div className="card__actions">
                    <button onClick={goTo(url)} className="btn btn--blue">
                      view job
                    </button>
                    <button
                      onClick={goTo("/post-a-job")}
                      className="btn btn--blue"
                    >
                      post another job
                    </button>
                    <button onClick={goTo("/")} className="btn btn--blue">
                      i’m done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withRouter(Success);
