import React from "react";

const WrapWith = props => {
  const { children, classes, onClick, ...all } = props;

  return (
    <div className={classes} onClick={onClick} {...all}>
      {children}
    </div>
  );
};

export default WrapWith;
