import React from 'react';

import './BlogPost.css';

import { DOMPurify } from '../../lib';

// TODO: paginate posts

const BlogPost = props => {

    const title = DOMPurify(props.post.title, { addTargetBlank: true });
    const content = DOMPurify(props.post.content);

    const author = props.post.author;
    const tags = props.post.tags;

    const dateOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };

    let date;

    if (props.post.date) {
      date = new Date(props.post.date)
        .toLocaleString('en-US', dateOptions);
    } else if (props.post.createdAt) {
      date = new Date(props.post.createdAt * 1000)
        .toLocaleString('en-US', dateOptions);
    } else {
      date = new Date()
        .toLocaleString('en-US', dateOptions);
    }

    return (
      <article className="section__entry blog-post">
        <h3 
          className="blog-post__title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h3>
        <h6 className="blog-post__author-and-date">{date}</h6>
        { author ? <h6 className="blog-post__author-and-date">Author: {author}</h6> : null }
        { tags ? <h6>Tags: {props.post.tags}</h6> : null }
        <div
          className="blog-post__body"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </article>
    );

}

export default BlogPost;