import React from "react";

import Logo from "./Logo";
import MobileNav from "./MobileNav";
import Navigation from "./Navigation";

import logoImageAbbreviated from "../../assets/images/logo.png";
import logoImageExpanded from "../../assets/images/logo-expanded.png";

const SiteHeader = props => {
  const zIndex = {
    zIndex: "200"
  };

  const logoImage = props.logoExpanded
    ? logoImageExpanded
    : logoImageAbbreviated;

  const expanded = "logo--expanded";

  return (
    <header className="header header--black" style={zIndex}>
      <div className="shell">
        <div className="header__entry">
          <MobileNav />

          <Logo
            className={`logo ${props.logoExpanded ? expanded : ""}`}
            image={logoImage}
            alt="We Work DevOps"
          />

          <Navigation />
        </div>
      </div>
    </header>
  );
};

export default SiteHeader;
