import React from "react";

import { 
  DOMPurify,
  stripHtml,
  truncateText 
} from "../../lib";

const Excerpt = props => {
 
  const excerpt = truncateText(
    stripHtml(
      DOMPurify(props.content)
      ), { Suffix: "&hellip;" });

  // using dangerouslySetInnerHTML to unescape html entities
  return (
    <p
      className={props.className}
      dangerouslySetInnerHTML={{ __html: excerpt }}
    />
  );
};

export default Excerpt;
