import React from "react";

import { Link } from "react-router-dom";

const Navigation = props => (
  <nav className="nav" style={props.style}>
    <ul>
      <li onClick={props.clicked}>
        <Link to="/">find your job</Link>
      </li>

      <li onClick={props.clicked}>
        <Link to="/post-a-job">post a job</Link>
      </li>

      <li onClick={props.clicked}>
        <Link to="/about">about</Link>
      </li>

      <li onClick={props.clicked}>
        <Link to="/blog">blog</Link>
      </li>
    </ul>
  </nav>
);

export default Navigation;
