import React from "react";

// This is visible in .article when applied successfully

const SuccessHead = props => {
  const classesList = ["article__head"];

  if (props.show) {
    classesList.push("article__head--visible");
  }

  const successHeadClasses = classesList.join(" ");

  return (
    <div className={successHeadClasses}>
      <i className="ico-check" />
      <h2>Success!</h2>
      <p>Your application has been submitted.</p>
    </div>
  );
};

export default SuccessHead;
