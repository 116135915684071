const actionTypes = {
  // blog
  FETCH_BLOG_POSTS_START: "FETCH_BLOG_POSTS_START",
  FETCH_BLOG_POSTS_SUCCESS: "FETCH_BLOG_POSTS_SUCCESS",
  FETCH_BLOG_POSTS_FAILURE: "FETCH_BLOG_POSTS_FAILURE",
  GET_BLOG_POSTS: "GET_BLOG_POSTS",
  // jobs
  FETCH_JOB_SUCCESS: "FETCH_JOB_SUCCESS",
  FETCH_JOB_FAILURE: "FETCH_JOB_FAILURE",
  FETCH_ALL_JOBS_SUCCESS: "FETCH_ALL_JOBS_SUCCESS",
  FETCH_ALL_JOBS_FAILURE: "FETCH_ALL_JOBS_FAILURE",
  FILTER_JOBS: "FILTER_JOBS",
  FILTER_JOBS_BY_ID: "FILTER_JOBS_BY_ID",
  // job posts
  SUBMIT_FORM_DATA: "SUBMIT_FORM_DATA",
  SET_JOB_SELECTION: "SET_JOB_SELECTION",
  UPDATE_FORM_DATA: "UPDATE_FORM_DATA",
  RESTART: "RESTART",
  SET_JOB_BILLING_CODE: "SET_JOB_BILLING_CODE",
  POST_JOB_SUCCESSFUL: "POST_JOB_SUCCESSFUL",
  POST_JOB_FAILURE: "POST_JOB_FAILURE",
  SET_STEP: "SET_STEP",
  // billing
  SET_PROCESSING_COUPON: "SET_PROCESSING_COUPON",
  SET_COUPON_ERROR: "SET_COUPON_ERROR",
  SET_BILLING_PLAN: "SET_BILLING_PLAN",
  UPDATE_BILLING: "UPDATE_BILLING",
  RESTART_BILLING: "RESTART_BILLING"
};

export default actionTypes;