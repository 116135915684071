import React from "react";

import { Link } from "react-router-dom";

const NotFound = () => (
  <div className="main">
    <section className="section-main">
      <div className="shell">
        <div className="section__entry">
          <h1>
            There's nothing here. If you were expecting something different you
            can try again, but chances are this page never existed or we've
            removed it for some reason. Thanks for visiting though, we
            appreciate you being here!
          </h1>
          <Link to="/" className="section-main__link">
            home
          </Link>
        </div>
      </div>
    </section>
  </div>
);

export default NotFound;
