import actionTypes from "./action-types";
import { fetchJobs, fetchJobById } from "../../api-requests";

// TODO: error handling - remove console.log

const fetchJobSuccess = job => {
  return {
    type: actionTypes.FETCH_JOB_SUCCESS,
    job
  };
};

const fetchJobFailure = error => {
  return {
    type: actionTypes.FETCH_JOB_FAILURE,
    error
  };
};

const fetchAllJobsSuccess = jobs => {
  return {
    type: actionTypes.FETCH_ALL_JOBS_SUCCESS,
    jobs
  };
};

const fetchAllJobsFailure = error => {
  return {
    type: actionTypes.FETCH_ALL_JOBS_FAILURE,
    error
  };
};

const filterJobId = id => {
  return {
    type: actionTypes.FILTER_JOBS_BY_ID,
    id
  };
};

const filterJobs = filteredJobs => {
  return {
    type: actionTypes.FILTER_JOBS,
    filteredJobs
  };
};

const getJobById = id => async dispatch => {
  try {
    const job = await fetchJobById(id);
    dispatch(fetchJobSuccess(job));
  } catch (error) {
    dispatch(fetchJobFailure(error));
  }
};

const getJobs = () => async dispatch => {
  try {
    const jobs = await fetchJobs();
    dispatch(fetchAllJobsSuccess(jobs));
  } catch (error) {
    dispatch(fetchAllJobsFailure(error));
  }
};

export { 
  getJobById, 
  getJobs, 
  filterJobId, 
  filterJobs
};
