import { useEffect, useState } from 'react';
import useScript from './use-script';


function useStripe (stripeSrc, stripeKey) {
  
  const [ stripe, setStripe ] = useState(null);
  const [ loaded, error ] = useScript(stripeSrc, 'stripe-js');

  useEffect(
    
    () => {
      
      if (error) {
        console.error('Failed to load Stripe');
      }

      if (loaded) {
        const stripeScriptElement = document.getElementById('stripe-js');
  
        function configureStripe () {
          setStripe(window.Stripe(stripeKey));
        }
  
        if (window.Stripe) {
          configureStripe();
        } 
        
        else {
          stripeScriptElement.addEventListener('load', configureStripe);
        }
  
        return () => stripeScriptElement.removeEventListener('load', configureStripe);
      
      }

    }, 
  
    [ loaded, error, stripeKey ]
  
  );

  return stripe;

}

export default useStripe;
