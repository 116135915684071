import { useRef, useState, useEffect } from 'react';

import { clearTimer } from '../lib';

function useDebouncedValue (value, delay = 200) {
  
  const [ debouncedValue, setDebouncedValue ] = useState(value);
  const timer = useRef(null);

  useEffect(
    () => {
      clearTimer(timer.current);
      // set debouncedValue to value (passed in) after the specified delay
      timer.current = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      
      // return a clean up function
      return (
        () => { clearTimer(timer.current); }
      );
    },
    // only re-call useEffect if the value or delay changes
    [ value, delay ]
  );

  return debouncedValue;
}

export default useDebouncedValue;