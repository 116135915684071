import React from "react";

import { Link } from "react-router-dom";

const Logo = props => (
  <Link className={props.className} to="/">
    <img
      src={props.image}
      alt={props.alt}
      style={{
        height: "100%"
      }}
    />
  </Link>
);

export default Logo;
