import React, { useState, useContext, useEffect, useRef, useReducer } from "react";
import "./JobPostForm.css";

import { useForm } from '../../hooks';

import { Link } from 'react-router-dom';

import {
  BreadCrumbs,
  Input,
  CheckboxOrRadio as Radio
} from "../../components/UI";

import { MarkdownEditor } from '../../components';

import { JobPostsContext } from '../../context';

import {
  submitFormData,
  setStep
} from "../../store/actions";

import { defaultReducer } from '../../store/reducers';

import { getDate } from "../../lib";

import validator from "./form-validation";

const  JobPostForm = () => {

  const context = useContext(JobPostsContext);
  const { state, dispatch } = context;
  
  const initialValidationState = {
    companyName: "",
    jobLocation: "",
    jobOptions: "",
    jobType: "",
    email: "",
    jobDescription: "",
    jobTitle: ""
  };

  const [ validationState, setValidationState ] = useReducer(defaultReducer, initialValidationState);
  const [ errorMessage, setErrorMessage ] = useState('');

  const submit = (values) => {

    const jobData = Object.assign({}, values);

    jobData.jobDate = getDate();

    // FIX THIS: change back-end to handle string instead of array (?)
    jobData.jobOptions = Array.isArray(jobData.jobOptions) ? jobData.jobOptions : [ jobData.jobOptions ];
    jobData.jobType = Array.isArray(jobData.jobType) ? jobData.jobType : [ jobData.jobType ];

    jobData.listingTerm = jobData.listingTerm || "0";
    jobData.billingCode = jobData.billingCode || "baseprice"

    const isFormValid = validator(jobData);
    
    if (isFormValid.valid) {
      dispatch(submitFormData(jobData));
      dispatch(setStep(1));
    } 
    
    else {

      const validation = {};
      isFormValid.issues.forEach(issue => {
        validation[issue] = 'invalid';
      });

      setValidationState(validation);

      setErrorMessage(
        "Please ensure that you've filled out all required fields correctly."
      );
    }

  };

  const { values, handleChange, handleSubmit } = useForm(submit, state.jobData);

  const displayErrorMessage = useRef(null);

  useEffect(
    () => {
      if (errorMessage) {
        displayErrorMessage.current = <p style={{ color: "red" }}>{errorMessage}</p>;
        window.scrollTo(0, 0);
      }    
    },
    [ errorMessage ]
  );

  return (
    <div className="main">
      
      <BreadCrumbs offset={true}>Post a Job. Do Good.</BreadCrumbs>
      
      <section id="post-a-job" className="section-form">
        
        <div className="shell">
          <header className="section__head">
            <p>
              When you post a job with We Work DevOps, you’re helping to build
              a community.
              <br />
              Listings are $299 per month, with a percentage going directly to
              our <Link to="/about">Community Fund</Link>.
            </p>
          </header>

          <div className="section__body">
            <div className="form">
              <form onSubmit={handleSubmit}>
                <div className="form__body">
                  <h5>Step 1: Add Your Company's Details</h5>
                  {displayErrorMessage.current}

                  <hr />

                  <Input
                    id="company-name"
                    type="text"
                    label="Company Name"
                    name="companyName"
                    labelClass={validationState.companyName}
                    placeholder="Your Organization's Name"
                    value={values.companyName || ""}
                    onChange={handleChange}
                  />

                  <Input
                    id="company-website"
                    type="text"
                    label="Company Website"
                    name="companyWebsite"
                    placeholder="www.yourcompany.com"
                    value={values.companyWebsite || ""}
                    onChange={handleChange}
                  />

                  <Input
                    id="email"
                    type="email"
                    label="Email Address"
                    name="email"
                    labelClass={validationState.email}
                    placeholder="yourname@company.com"
                    value={values.email || ""}
                    onChange={handleChange}
                    required
                  />
                  <label className="under-label">
                    <sup>*</sup>This address is where we’ll confirm your
                    listing and send your receipt.
                  </label>

                  <Input
                    id="community-partner"
                    type="text"
                    label="Community Partner (Optional)"
                    name="communityPartner"
                    placeholder="Your Suggested Community Partner"
                    value={values.communityPartner || ""}
                    onChange={handleChange}
                  />
                  <label className="under-label">
                    <sup>*</sup>Suggest an organization to be considered for a
                    donation from our Community Fund.
                  </label>
                </div>

                <div className="form__body">
                  <h5>Step 2: Add Job Details</h5>

                  <hr />

                  <Input
                    id="job-title"
                    type="text"
                    label="Job Title"
                    name="jobTitle"
                    labelClass={validationState.jobTitle}
                    placeholder="Job Title"
                    value={values.jobTitle || ""}
                    onChange={handleChange}
                    required
                  />

                  <Input
                    id="job-location"
                    type="text"
                    label="Job Location"
                    name="jobLocation"
                    labelClass={validationState.jobLocation}
                    placeholder="Example: Denver, CO"
                    value={values.jobLocation || ""}
                    onChange={handleChange}
                    required
                  />

                  <Radio
                    name="jobOptions"
                    groupLabel="Job Options"
                    type="radio"
                    labelClass={validationState.jobOptions}
                    optionTitles={["Remote", "On Site", "Remote OK"]}
                    selectedOptions={values.jobOptions || ''}
                    onChange={handleChange}
                  />

                  <Radio
                    name="jobType"
                    groupLabel="Job Type"
                    type="radio"
                    labelClass={validationState.jobType}
                    optionTitles={["Full Time", "Part Time", "Contract"]}
                    selectedOptions={values.jobType || ''}
                    onChange={handleChange}
                  />

                  <MarkdownEditor
                    label="Job Description"
                    labelClass={validationState.jobDescription}
                    onChange={handleChange}
                    name="jobDescription"
                    value={values.jobDescription}
                  />

                  <Input
                    id="salary-range"
                    type="text"
                    name="salaryRange"
                    label="Salary Range (Optional)"
                    placeholder="Example: USD 90k-120k"
                    value={values.salaryRange || ""}
                    onChange={handleChange}
                  />

                  <Input
                    id="how-to-apply"
                    type="textarea"
                    label="How To Apply (Optional)"
                    name="howToApply"
                    placeholder="Example: https://candidates.can.apply.here/12345"
                    value={values.howToApply || ""}
                    onChange={handleChange}
                  />
                  <label className="under-label">
                    <sup>*</sup>If you don't fill out this field, applications
                    will be sent to your registered email.
                  </label>
                </div>

                <div className="form__actions">
                  <button type="submit" className="form__btn btn btn--blue">
                    REVIEW JOB
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

};

export default JobPostForm;
