import React, { Fragment } from "react";

function camelCase(s) {
  return s
    .replace(/\s[a-z]/g, c => c.toUpperCase()) //convert first char of each word to UPPERCASE
    .replace(/-/g, " ") //convert all hyphens to spaces
    .replace(/\s+/g, "") //remove spaces
    .replace(/\W/g, "") // remove non-word chars
    .replace(/^[A-Z]/g, c => c.toLowerCase()); //convert first char to lowercase
}

function capitalize(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

const CheckboxOrRadio = props => {
  const {
    classes,
    type,
    name,
    groupLabel,
    onChange,
    optionTitles,
    selectedOptions,
    required,
    labelClass
  } = props;

  const options = optionTitles.map(o => [camelCase(o), o]);

  return (
    <Fragment>
      {groupLabel ? (
        <label
          style={{ marginTop: "1em" }}
          className={`form__label ${labelClass}`}
        >
          {groupLabel}
        </label>
      ) : null}

      {options ? (
        <div
          style={{ marginBottom: "1em" }}
          className="form__row form__row--radio"
        >
          {options.map(option => {
            const elementId = `${type || "radio"}-group-${option[0]}`;
            return (
              <div key={option[0]} className="radio radio--alt">
                <input
                  required={required}
                  onChange={onChange}
                  id={elementId}
                  type={type || "radio"}
                  className={classes}
                  name={name}
                  value={option[1]}
                  defaultChecked={
                    selectedOptions
                      ? selectedOptions.includes(option[1])
                      : false
                  }
                />
                <label htmlFor={elementId}>{capitalize(option[1])}</label>
              </div>
            );
          })}
        </div>
      ) : null}
    </Fragment>
  );
};

export default CheckboxOrRadio;
