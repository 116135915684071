import actionTypes from './action-types';

import { fetchBillingPlan } from '../../api-requests';

import { isEmpty } from '../../lib';

const setProcessingCoupon = (processingCoupon = true) => {
  return {
    type: actionTypes.SET_PROCESSING_COUPON,
    processingCoupon
  };
};

const setCouponError = (error = null, message = null) => {
  return {
    type: actionTypes.SET_COUPON_ERROR,
    error,
    message
  };
};

const setBillingPlan = billingPlan => {
  return  {
    type: actionTypes.SET_BILLING_PLAN,
    billingPlan
  };
};

const updateBilling = billing => {
  return {
    type: actionTypes.UPDATE_BILLING,
    billing
  };
};

// thunk
const getBillingPlan = (billingCode, callback) => async dispatch => {

  dispatch(setProcessingCoupon(true));

  try {
    const billingPlan = await fetchBillingPlan(billingCode);

    // TODO: update back-end API to return 404
    if (isEmpty(billingPlan)) {
      throw new Error('coupon not found');
    }    
    dispatch(setBillingPlan(billingPlan));
    callback(billingPlan);
  }

  catch (error) {
    dispatch(setCouponError(error));
  }

};

const restartBilling = () => {
  return {
    type: actionTypes.RESTART_BILLING
  };
};

export {
  updateBilling,
  getBillingPlan,
  setCouponError,
  restartBilling
};