import React, { useCallback } from 'react';

import './PaginationNav.css';

import { scrollToTop } from '../../lib';

const PaginationNav = ({ paginator, setPage }) => {
  
  const { 
    currentPage, 
    totalPages, 
    pages,
    nextPage,
    previousPage 
  } = paginator;

  const goToPage = useCallback(
    pageNumber => event => {
      event.preventDefault();
      if (!pageNumber) return;
      setPage(pageNumber);
      scrollToTop();
    },
    [ setPage ]
  );

  // disable link for target item
  const conditionallyDisabled = useCallback(
    compare => `${currentPage === compare ? ' pagination__page-item--disabled' : ''}`,
    [ currentPage ]
  );

  if (totalPages <= 1) {
    // do not display pagination if there is only 1 page
    return null;
  }

  
  return (
    <nav aria-label="pagination">
      <ul className="pagination">
        <li className={'pagination__page-item' + conditionallyDisabled(1)}>
          <a href={`/page/1`} onClick={goToPage(1)}>First</a>
        </li>
        <li className={'pagination__page-item' + conditionallyDisabled(1)}>
          <a href={`/page/${previousPage}`} onClick={goToPage(previousPage)}>Previous</a>
        </li>
        {pages.map(pageNumber =>
          <li key={pageNumber} className={'pagination__page-item' + conditionallyDisabled(pageNumber)}>
            <a href={`/page/${pageNumber}`} onClick={goToPage(pageNumber)}>{pageNumber}</a>
          </li>
        )}
        <li className={'pagination__page-item' + conditionallyDisabled(totalPages)}>
          <a href={`/page/${nextPage}`} onClick={goToPage(nextPage)}>Next</a>
        </li>
        <li className={'pagination__page-item' + conditionallyDisabled(totalPages)}>
          <a href={`/page/${totalPages}`} onClick={goToPage(totalPages)}>Last</a>
        </li>
      </ul>
    </nav>
);

}

export default PaginationNav;