/*
  Based on code by Jake Archibald:
  https: //jakearchibald.com/2014/offline-cookbook/
*/

/**
 * A race function that returns the first promise to resolve
 * in an array of promises. This works as an alternative to
 * Promise.race: it rejects only if all promises reject. By
 * contrast, Promise.race rejects when a single promise rejects.
 *
 * @param {array} promises An array of promises
 * @param {string} [errorMessage = 'all failed'] The error message sent if all promises reject
 * @returns {Promise} The first promise to resolve
 */
function promiseAny (promises, errorMessage = 'all failed') {
  return new Promise((resolve, reject) => {
    // make sure promises are all promises
    promises = promises.map(p => Promise.resolve(p));
    // resolve this promise as soon as one resolves
    promises.forEach(p => p.then(resolve));
    // reject if all promises reject
    promises.reduce((a, b) => a.catch(() => b))
      .catch(() => reject(new Error(errorMessage)));
  });
}

export default promiseAny;