import { useState, useEffect } from 'react';
import paginate from '../lib/paginate';

function selectItems (items = [], paginator) {
  
  if (!Array.isArray(items) || !items.length) {
    return [];
  }

  if (!paginator) {
    return items;
  }

  if (!(paginator.hasOwnProperty('startItem') && paginator.hasOwnProperty('endItem'))) {
    return items;
  }

  return items.slice(paginator.startItem, paginator.endItem + 1);
}

function usePagination (items = [], pageSize = 1, currentPage = 1) {

  const [ page, setPage ] = useState(currentPage);
  const [ pagination, setPagination ] = useState(paginate(items.length, pageSize));
  const [ paginator, setPaginator ] = useState(pagination[page - 1]);  
  const [ selectedItems, setSelectedItems ] = useState(selectItems(items, paginator));

  useEffect(
    () => {
      setPagination(paginate(items.length, pageSize));
    },
    [ items.length, pageSize ]
  );

  useEffect(
    () => {      
      setPaginator(pagination[page - 1]);      
    },
    [ pagination, page ]
  );

  useEffect(
    () => {
      setSelectedItems(selectItems(items, paginator));
    },
    [ items, paginator ]
  );

  return [ paginator, selectedItems, setPage ];

}

export default usePagination;