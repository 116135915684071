
/**
 * Paginator objects situate items in a sequence of pages
 * @param {number} currentPage The individual page number of the paginator
 * @param {number} totalItems Total number of items
 * @param {number} pageSize Number of items per page
 */
export class Paginator {

  constructor (currentPage = 1, totalItems = 0, pageSize = 1) {
    this.currentPage = currentPage;
    this.totalItems = totalItems;
    this.pageSize = pageSize;

    const totalPages = Math.ceil(this.totalItems / this.pageSize);

    this.totalPages = totalPages ? totalPages : 1;
    this.order();
  }

  // Generate page order
  order () {
    
    /* 
      Logic:

      - there are at most 10 page links shown at any time;
      - the current page is at the 6th position, 
        except when the current page is below 6 or less than 4 
        from the last position;

      Examples:

      [1] 2 3 4 5 6 7 8 9 10
      1 [2] 3 4 5 6 7 8 9 10
      1 2 [3] 4 5 6 7 8 9 10
      1 2 3 [4] 5 6 7 8 9 10
      1 2 3 4 [5] 6 7 8 9 10
      1 2 3 4 5 [6] 7 8 9 10
      2 3 4 5 6 [7] 8 9 10 11
      3 4 5 6 7 [8] 9 10 11 12
      4 5 6 7 8 [9] 10 11 12 13
      5 6 7 8 9 [10] 11 12 13 14
      6 7 8 9 10 [11] 12 13 14 15
      6 7 8 9 10 11 [12] 13 14 15
      6 7 8 9 10 11 12 [13] 14 15
      6 7 8 9 10 11 12 13 [14] 15
      6 7 8 9 10 11 12 13 14 [15] 

      Inspiration / credits: 
      https://jasonwatmore.com/post/2017/03/14/react-pagination-example-with-logic-like-google

    */

    // start and end pages
    if (this.totalPages <= 10) {
      // less than 10 pages, show all
      this.startPage = this.totalPages ? 1 : 0;
      this.endPage = this.totalPages;
    }

    else {
      // more than 10 total pages, calculate start and end pages
      if (this.currentPage <= 6) {
        this.startPage = 1;
        this.endPage = 10;
      }

      else if (this.currentPage + 4 >= this.totalPages) {
        this.startPage = this.totalPages - 9;
        this.endPage = this.totalPages;
      }

      else {
        this.startPage = this.currentPage - 5;
        this.endPage = this.currentPage + 4;
      }

    }

    // metadata
    this.hasPrevious = !(this.currentPage === 1);
    this.hasNext = !(this.currentPage === this.totalPages);
    this.previousPage = this.hasPrevious ? this.currentPage - 1 : null;
    this.nextPage = this.hasNext ? this.currentPage + 1 : null;

    // first and last item indexes of current page
    this.startItem = (this.currentPage - 1) * this.pageSize;

    this.endItem = (() => {
      
      const remains = this.totalItems % this.pageSize;

      let endItem;

      if (this.hasNext || !remains) {
        endItem = this.startItem + this.pageSize - 1;
      }

      else {
        endItem = this.startItem + remains - 1;
      }

      return endItem;

    })();
    

    // array of page numbers to exhibit
    this.pages = [...Array((this.endPage + 1) - this.startPage).keys()].map(i => this.startPage + i);
    
  }

}


/**
 * Given a total number of items and number of items per page, 
 * return an array of paginators referencing the items by index
 * @param  {number} itemsTotal Total number of items to paginate
 * @param  {number} pageSize Number of items per page
 * @return {array}  An array of paginator objects
 */
function paginate (itemsTotal = 0, pageSize) {

  const pages = [];

  let cursor = 0, currentPage = 1;
  
  do {
    pages.push(new Paginator(currentPage, itemsTotal, pageSize));
    currentPage++;
    cursor += pageSize;
  } while (cursor < itemsTotal);

  return pages;
  
}

export default paginate;
