import { updateObject } from "../../lib";
import actionTypes from "../actions/action-types";

// TODO: error handling
const initialState = {
  loaded: false,
  jobs: [],
  filteredJobs: [],
  jobLoaded: false,
  job: {},
  error: null
};

const fetchJobSuccess = (state, action) => {
  if (action.job) {
    return updateObject(state, {
      jobLoaded: true,
      job: action.job
    });
  } else {
    return state;
  }
};

const fetchJobFailure = (state, action) => {
  return updateObject(state, {
    jobLoaded: true,
    error: action.error
  });
};

const fetchAllJobsSuccess = (state, action) => {
  return updateObject(state, {
    loaded: true,
    jobs: action.jobs
  });
};

const fetchAllJobsFailure = (state, action) => {
  return updateObject(state, {
    loaded: true,
    error: action.error
  });
};

const filterJobs = (state, action) => {
  return updateObject(state, {
    filteredJobs: action.filteredJobs
  });
};

const filterJobsById = (state, action) => {
  let singleJob = state.jobs.filter(job => job.id === action.id);
  if (singleJob.length) {
    singleJob = singleJob[0];
    return updateObject(state, {
      jobLoaded: true,
      job: singleJob
    });
  } else {
    return state;
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_JOB_SUCCESS:
      return fetchJobSuccess(state, action);
    case actionTypes.FETCH_JOB_FAILURE:
      return fetchJobFailure(state, action);
    case actionTypes.FETCH_ALL_JOBS_SUCCESS:
      return fetchAllJobsSuccess(state, action);
    case actionTypes.FETCH_ALL_JOBS_FAILURE:
      return fetchAllJobsFailure(state, action);
    case actionTypes.FILTER_JOBS_BY_ID:
      return filterJobsById(state, action);
    case actionTypes.FILTER_JOBS:
      return filterJobs(state, action);
    default:
      return state;
  }
};

export {
  initialState,
  reducer
};
