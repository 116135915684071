import { useReducer, useCallback } from 'react';
import { defaultReducer } from '../store/reducers';


const useForm = (callback, initialValues = {}) => {

  const [values, setValues] = useReducer(defaultReducer, initialValues);

  const handleSubmit = useCallback(event => {
    if (event) { event.preventDefault(); }
    return callback(values);
  }, [ values, callback ]);

  const handleChange = useCallback(event => {
    if (event && event.persist) { event.persist(); }
    return setValues({ [event.target.name]: event.target.value });
  }, [ setValues ]);

  return {
    handleChange,
    handleSubmit,
    values
  };
};

export default useForm;