import React, { useContext } from 'react';

import { Redirect } from 'react-router-dom';

import { JobPostsContext } from '../../context';

import { basePath, routesConfig } from '../JobPostRoutes';

const JobPostCheckout = () => {

  const context = useContext(JobPostsContext);
  const step = context.state.step;

  return (
    <Redirect to={`${basePath}/${routesConfig[step].path}`} />
  );

};

export default JobPostCheckout;