import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ErrorHandler } from './components';

import { BlogProvider, JobsProvider, JobPostsProvider } from './context';

import { About, JobPostCheckout, JobPostRoutes, NotFound } from './components';
import { Blog, JobList, JobDetails } from './containers';

const Routes = () => (
  <ErrorHandler>
      <Switch>     

        <Route path="/about" component={About} />

        <Route path='/blog'>
          <BlogProvider><Blog /></BlogProvider>
        </Route>

        <Route path="/post-a-job/:step" >
          <JobPostsProvider><JobPostRoutes /></JobPostsProvider>
        </Route>
        
        <Route exact path="/post-a-job" >
          <JobPostsProvider><JobPostCheckout /></JobPostsProvider>
        </Route>

        <Route path="/jobs/:id" >
          <JobsProvider><JobDetails /></JobsProvider>
        </Route>

        <Route exact path="/" >
          <JobsProvider><JobList /></JobsProvider>
        </Route>

        <Route component={NotFound} />
        
      </Switch>
  </ErrorHandler>
);

export default Routes;