import React, { useMemo } from 'react';

import { useReducerWithThunk } from '../hooks';

import { jobPostsReducer, billingReducer } from '../store/reducers';

const JobPostsContext = React.createContext({});

function JobPostsProvider ({ children }) {
  const [ state, dispatch ] = useReducerWithThunk(jobPostsReducer.reducer, jobPostsReducer.initialState);
  const [ billingState, dispatchBilling ] = useReducerWithThunk(billingReducer.reducer, billingReducer.initialState);
  const value = useMemo(() => ({ state, dispatch, billingState, dispatchBilling }), [ state, dispatch, billingState, dispatchBilling ]);
  return (
    <JobPostsContext.Provider value={value}>{children}</JobPostsContext.Provider>
  );
}

const JobPostsConsumer = JobPostsContext.Consumer;

export {
  JobPostsContext,
  JobPostsProvider,
  JobPostsConsumer
};