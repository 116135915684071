import React, { useState, useEffect } from "react";

import { debounce } from '../../lib';

import SiteHeader from "../SiteHeader";
import SiteFooter from "../SiteFooter";

const Layout = ({ breakpoint = 1024, children }) => {

  const [ reachedBreakpoint, setReachedBreakpoint ] = useState(false);

  // debounced: will execute n ms after it stops being called
  const  updateLayout = debounce(() => {
    if (window.innerWidth < breakpoint) {
      setReachedBreakpoint(false);
    } else {
      setReachedBreakpoint(true);
    }
  }, 10);

  useEffect(() => {
  
    updateLayout();
    window.addEventListener('resize', updateLayout);
     
    return () => {
      window.removeEventListener('resize', updateLayout);
    }
  }, [updateLayout]);
  
  return (
    <div className="wrapper">
      <SiteHeader logoExpanded={ reachedBreakpoint } />
        <main>{children}</main>
      <SiteFooter />
    </div>
  );

};

export default Layout;